/*
*   File : unlock.js
*   Author : https://evoqins.com
*   Description : Unlock Container for setting and verifying PIN
*   Version : 1.0.0
*/
// import packages
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom/dist";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Modal } from "bootstrap";

// import components
import { CustomHeader } from "../../Components/Navigation";
import { CustomPINInput } from "../../Components/FormElements";
import { AuthSlider } from "../../Components/Slider";
import { PrimaryButton } from "../../Components/Buttons";
import { SelectCareerModal } from "../../Components/Modal";
import { Loader } from "../../Components/Other";
import { Icon } from "../../Components/Icon";


// import helper
import APIService from "../../Services/api-service";
import store from "../../Store";
import { _getGeneralData, _getProfile, _getSignupProgress } from "../../Helper/api";
import { _logout } from "../../Helper/helper";

const Unlock = () => {
    const navigate = useNavigate();
    // const location = useLocation();

    const REFRESH_TOKEN = useSelector(state => state.Reducer.REFRESH_TOKEN);

    const [pin, setPin] = useState("");
    const [pinError, setPinError] = useState("");

    const [name, setName] = useState("");
    const [apiLoader, setApiLoader] = useState(false);

    const [validateLoader, setValidateLoader] = useState(true);

    const [refreshToken, setRefreshToken] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [investorType, setInvestorType] = useState([]);




    useEffect(() => {

        const handleNavigation = () => {
            const modalElement = document.querySelector('.modal');
            const modalBackdrop = document.querySelector('.modal-backdrop');

            if (modalElement) {
                // Hide or remove the modal
                modalElement.style.display = 'none'; // You can also use `modalElement.remove();` if you want to completely remove it
            }

            if (modalBackdrop) {
                modalBackdrop.remove();
            }

            document.body.removeAttribute('style');
            document.body.removeAttribute('class');
        };

        handleNavigation();

        // Add event listener for the 'popstate' event
        window.addEventListener('popstate', handleNavigation);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('popstate', handleNavigation);
        };

    }, []);


    useEffect(() => {
        if (REFRESH_TOKEN !== null) {
            setRefreshToken(REFRESH_TOKEN);
            _validateRefreshToken(REFRESH_TOKEN);
        } else {
            navigate('/');
        }

        //eslint-disable-next-line
    }, [REFRESH_TOKEN])

    useEffect(() => {
        if (investorType.length !== 0) {
            const modal = new Modal(document.getElementById("select-career"))
            modal.show();
        }
    }, [investorType]);


    // useEffect(() => {
    //     if (location.state !== null) {
    //         setRefreshToken(location.state.refresh_token);
    //         _validateRefreshToken(location.state.refresh_token)
    //     }
    // }, [])


    // PIN value handler
    function _handlePIN(input_value) {
        if (input_value.length <= 4) {
            setPin(input_value);
        }
        setPinError("");
    }

    // updating investor type to close modal
    function _handleUpdateInvestorType() {
        setInvestorType([]);
        store.dispatch({
            type: "LOGIN_STATUS",
            payload: true
        })
        navigate("/", {
            replace: true,
        });
        store.dispatch({
            type: "REFRESH_TOKEN",
            payload: refreshToken
        })
        sessionStorage.setItem("mintit_access_token", accessToken);
        Cookies.set("mintit_refresh_token", refreshToken, {
            expires: 90,
            domain: process.env.REACT_APP_DOMAIN,
            path: '/'
        });
    }

    // validation for submitting PIN
    function _submitPIN() {
        let valid = true;
        if (pin === "") {
            setPinError("PIN cannot be empty");
            valid = false;
        }
        if (pin !== "" && pin.length !== 4) {
            setPinError("Invalid PIN");
            valid = false;
        }
        if (valid === true) {
            _verifyPin();
        }
    }

    function _navigateToRiskProfile() {
        store.dispatch({
            type: "LOGIN_STATUS",
            payload: true
        })
        navigate("/risk-profile", {
            replace: true,
        });
        store.dispatch({
            type: "REFRESH_TOKEN",
            payload: refreshToken
        })
        Cookies.set("mintit_refresh_token", refreshToken, {
            expires: 90,
            domain: process.env.REACT_APP_DOMAIN,
            path: '/'
        });
        sessionStorage.setItem("mintit_access_token", accessToken);
    }
    // API - verify pin to call when user comes from login flow
    const _verifyPin = async () => {
        setApiLoader(true);
        const url = "/auth/verify-pin"
        const request = {
            "pin": pin
        }
        await APIService(true, url, request, refreshToken).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                setRefreshToken(response.data.refresh_token);
                setAccessToken(response.data.access_token);
                _getGeneralData(response.data.access_token);
                _getSignupProgress(response.data.access_token);
                _getProfile(response.data.access_token).then((profile_response) => {
                    if (profile_response.get_investor_type === null) {
                        _getInvestorTypes(response.data.access_token);
                    } else {
                        store.dispatch({
                            type: "REFRESH_TOKEN",
                            payload: response.data.refresh_token
                        })

                        Cookies.set("mintit_refresh_token", response.data.refresh_token, {
                            expires: 90,
                            domain: process.env.REACT_APP_DOMAIN,
                            path: '/'
                        });
                        navigate("/", {
                            replace: true,
                        });
                        store.dispatch({
                            type: "LOGIN_STATUS",
                            payload: true
                        })
                        sessionStorage.setItem("mintit_access_token", response.data.access_token);

                    }
                })

            } else {
                setTimeout(() => {
                    setPin("");
                }, 500);
                toast.error(response.message, {
                    type: "error",
                });

            }
            setApiLoader(false);
        })
    }

    // API - validate refresh token
    const _validateRefreshToken = (token) => {
        const url = "/auth/validate-refresh-token";


        APIService(false, url, null, token).then((response) => {
            if (response.status_code === 200) {
                setName(response.data.name);
            } else {
                _logout();
            }
            setValidateLoader(false)
        })
    }


    // API - get investor types
    const _getInvestorTypes = (token) => {
        const url = "/general/investor-types"
        APIService(false, url, null, token).then((response) => {
            if (response.status_code === 200) {
                setInvestorType(response.data);
            } else {
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }
    return (
        <Fragment>
            <CustomHeader />
            <div className="d-flex flex-column align-items-center justify-content-end padding-96px-top">

                {
                    validateLoader ?
                        <Loader height="h-472px e-login-wrapper border-radius-32px" />
                        :
                        <div className="e-login-wrapper border-radius-32px ">
                            <div className="row">
                                <div className="col-lg-5 col-md-5">
                                    <AuthSlider />
                                </div>
                                <div className="col-lg-1 col-md-1">
                                    <div className="e-login-seperator position-relative"></div>
                                </div>
                                <div className="col-lg-6 col-md-6 padding-52px-top">
                                    <Fragment>
                                        <h3 className="color-dark-jungle-green mb-1 e-font-24 e-line-height-36 e-montserrat-semi-bold letter-spacing-minus2em truncate-first-line">Hi, {name}!</h3>
                                        <p className="color-blue-gray e-font-14 e-line-height-24 e-montserrat-regular letter-spacing-minus2em mb-3">
                                            Enter the 4 digit PIN and get started.
                                        </p>

                                        <CustomPINInput id="pin-input"
                                            pinString={pin}
                                            autoFocus={true}
                                            title="Enter your pin"
                                            postfix="*"
                                            error={pinError}
                                            className="mb-3"
                                            handleChange={_handlePIN}
                                            onSubmit={_submitPIN} />
                                        <Link to="/forgot-pin" className="no-decoration color-rebecca-purple e-font-14 e-line-height-20 e-montserrat-medium e-link">Forgot PIN</Link>
                                        <PrimaryButton className="w-100 margin-32px-top padding-10px-tb"
                                            label="Continue"
                                            disabled={apiLoader}
                                            onPress={_submitPIN} />
                                    </Fragment>
                                </div>
                            </div>
                        </div>
                }

                <div className="d-flex flex-wrap align-items-center mt-4 gap-10px padding-32px-bottom px-sm-0 px-4">
                    {/* <p className="color-charcoal-gray e-font-16 e-line-height-28 e-montserrat-semi-bold mb-0">Download App</p>
                    <div className="d-flex align-items-center padding-10px-all gap-10px cursor-pointer e-social-icon" onClick={() => window.open("https://apps.apple.com/app/mintit/id6737547969", "_blank")}>
                        <Icon icon='ios-store'
                            size={28} />
                        <p className="color-charleston-green e-font-16 e-line-height-20 e-montserrat-semi-bold mb-0">AppStore</p>
                    </div>
                    <div className="d-flex align-items-center padding-10px-all ps-0 gap-10px cursor-pointer e-social-icon" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.mintit.in", "_blank")}>
                        <Icon icon='play-store'
                            size={28} />
                        <p className="color-charleston-green e-font-16 e-line-height-20 e-montserrat-semi-bold mb-0">PlayStore</p>
                    </div> */}
                </div>
            </div>

            {
                investorType.length !== 0 &&
                <SelectCareerModal data={investorType}
                    token={accessToken}
                    navigate={_navigateToRiskProfile}
                    confirm={_handleUpdateInvestorType} />
            }

        </Fragment >
    )
}

export default Unlock